/* copying input/textarea styles for selects */

.md-form .prefix ~ select{
    margin-left: 2.5rem;
    width: calc(100% - 2.5rem);
}

.md-form select:focus + label{
    color: #4285f4;
}

.form-control.datepicker {
    margin-left: 2.5rem;
    width: calc(100% - 2.5rem);
}

.react-datepicker-wrapper:focus-within ~ label {
    color: #4285f4;
}

.form-inputs .md-form.form-group {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

